<template>
  <div class="container">
    <div class="aaa">
      <img src="../../../assets/agent/img/t_title.png">
      <h3>{{ this.user.empname }}团队<span>({{ this.tddata.length }})</span>
        <!-- <md-icon name="edit"></md-icon> -->
      </h3>
    </div>
    <div class="content">
      <div class="top">
        <div class="topfirst">
          <div class="topfirstone">{{zfnum}}</div>
          <div class="topfirstsecond">{{hknum}}</div>
          <div class="topfirstthird">{{fwnum}}</div>
        </div>
        <div class="bottomfirst">
          <div class="bottomfirstone">
            <md-icon name="edit"
                     :color="colorprimary"></md-icon>转发
          </div>
          <div class="bottomfirstsecond">
            <md-icon name="authentication"
                     :color="colorprimary"></md-icon>获客
          </div>
          <div class="bottomfirstthird">
            <md-icon name="visible"
                     :color="colorprimary"></md-icon>访问量
          </div>
        </div>
      </div>
    </div>
    <div class="teamTable">
      <!-- <md-icon name="arrow-down" class="zhong"></md-icon> -->
      <!-- <md-icon name="arrow-down" class="zhong"></md-icon> -->
      <div class="bottomsecond">
        <div class="riqi">
          <div @click='prev'
               class="riqileft">
            <span :v-model="prevs">
              <md-icon name="arrow-left"></md-icon>{{prevs}}
            </span>月
          </div>
          <div>
            <span :v-model="year">{{year}}</span>年
            <span :v-model="now">{{now}}</span>月
          </div>
          <div @click='next'
               class="riqiright">
            <span :v-model="nexts">{{nexts}}</span>月<md-icon name="arrow-right"></md-icon>
          </div>
        </div>
        <div class="biaoge">
          <table>
            <tr>
              <td>姓名</td>
              <td>转发</td>
              <td>获客</td>
              <td>访问量</td>
            </tr>
            <tr v-for="(item, index) in tddata"
                :key="index">
              <td>{{ item.empname|getSub(8) }}</td>
              <td class="biaogetd">{{ item.zfnum }}</td>
              <td class="biaogetd">{{ item.hknum }}</td>
              <td class="biaogetd">{{ item.fwnum }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTDMonCount } from '@/api/agent/agent/index'
import { getStorage } from '@/lib/util'
import { Icon } from 'mand-mobile'
import { wechatshare } from '@/lib/wechat_share'
export default {
  name: 'icon-demo',
  components: {
    [Icon.name]: Icon
  },
  data () {
    return {
      prevs: 10,
      year: 2019,
      now: 11,
      nexts: 12,
      user: '',
      fwnum: '',
      hknum: '',
      zfnum: '',
      tddata: [],
      colorprimary: ''
    }
  },
  created () {
    this.colorprimary = getStorage('theme', '')
    var d = new Date()
    var month = (d.getMonth() + 1)
    var year = d.getFullYear()
    this.year = year
    this.now = month
    if (month == '1') {
      this.prevs = 12
      this.nexts = (month - 0) + 1
    } else if (month == '12') {
      this.prevs = (month - 0) - 1
      this.nexts = 1
    } else {
      this.prevs = (month - 0) - 1
      this.nexts = (month - 0) + 1
    }
    this.getTDMonCount()
  },
  filters: {
    getSub (val, len) {
      if (val) {
        let str = ''
        val.length > len ? str = val.substr(0, len) + '...' : str = val
        return str
      } else {
        return ''
      }
    }
  },
  mounted () {
    wechatshare('', '', '', '', '', '', true)
  },
  methods: {
    prev () {
      // console.log(this.prevs)
      if (this.prevs == 1) {
        this.prevs = 12
        this.year = this.year
        this.now = 1
        this.nexts = this.now + 1
      } else if (this.prevs == 12) {
        this.prevs = this.prevs - 1
        this.year = this.year - 1
        this.now = 12
        this.nexts = 1
      } else {
        if (this.nexts == 1) {
          this.prevs = this.prevs - 1
          this.year = this.year
          this.now = this.now - 1
          this.nexts = 12
        } else {
          this.prevs = this.prevs - 1
          this.year = this.year
          this.now = this.now - 1
          this.nexts = this.nexts - 1
        }
      }
      this.getTDMonCount()
    },
    next () {
      // console.log(this.nexts)
      if (this.nexts == 12) {
        this.prevs = this.prevs + 1
        this.year = this.year
        this.now = 12
        this.nexts = 1
      } else if (this.nexts == 1) {
        this.prevs = 12
        this.year = this.year + 1
        this.now = 1
        this.nexts = this.nexts + 1
      } else {
        if (this.prevs == 12) {
          this.prevs = 1
          this.year = this.year
          this.now = this.now + 1
          this.nexts = this.nexts + 1
        } else {
          this.prevs = this.prevs + 1
          this.year = this.year
          this.now = this.now + 1
          this.nexts = this.nexts + 1
        }
      }
      this.getTDMonCount()
    },
    getTDMonCount () {
      this.user = getStorage('u_s', {})
      let yearmon = this.year + '-' + this.now
      getTDMonCount({ empno: this.user.empno, yearmon: yearmon }).then((res) => {
        // console.log(res)
        this.fwnum = res.data.data.fwnum
        this.hknum = res.data.data.hknum
        this.zfnum = res.data.data.zfnum
        this.tddata = res.data.data.tddata
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './index.styl';
</style>
